<template>
  <v-container style="margin-bottom: 100px">
    <customer-info logo="true" dayDateWithBackBtn="true" customer="true" />

    <v-row>
      <v-col>
        <span>
          F+3/12<br />
          10/26/2020 11/17/2020<br />
          Day 1 of 12
        </span>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="12">
        <h1>REASSIGN PROJECT</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="3">
        <v-select
          label="Today Only"
          :items="todayOnlyValues"
          v-model="reassignProject.todayOnly"
        ></v-select>
      </v-col>

      <v-col cols="12" md="3">
        <v-select
          label="Remainder of Project"
          :items="projectReminderValues"
          v-model="reassignProject.projectReminder"
        ></v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomerInfo from "./CustomerInfo";

export default {
  name: "TodayReassign",
  components: { CustomerInfo },
  data() {
    return {
      reassignProject: {
        todayOnly: "",
        projectReminder: "",
      },
      projectReminderValues: [""],
      todayOnlyValues: [""],
    };
  },
};
</script>

<style scoped></style>
